@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'material-icons/iconfont/material-icons.css';

@font-face {
  font-family: "NewsonGLS";
  src: url("./assets/NewsonGLS-WEB-Regular.woff") format("woff");
}

body {
  margin: 0;
  font-family: "NewsonGLS";
}

.form-control {
  border-radius: 10px;
  border: 1px solid #aeaeae;
  font-size: 16px;
  color: #000;
  padding: 10px 10px 10px 10px;
  height: 44px;
  font-family: "NewsonGLS";
}

.form-control:focus {
  border: 1px solid #061ab1;
  box-shadow: none;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.accordion-header {
  border-bottom: 2px solid #dedede;
  background: #fff;
  padding: 10px 10px 10px 10px;
  line-height: 24px;
}

.accordion-item {
  border: none;
}

.accordion .accordion-button {
  background: none;
  box-shadow: none;
  color: #000;
  font-size: 1.3rem;
  font-weight: 700;
}

.accordion-button::after {
  position: absolute;
  top: calc(50% - (24px / 2));
  right: 22px;
  display: block;
  width: 24px;
  height: 24px;
  background: #061ab1 url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M14 8h-4V4H8v4H4v2h4v4h2v-4h4z'/%3E%3C/svg%3E") no-repeat 0 0;
  background-size: 24px 24px;
  content: '\a0';
}

.accordion-header:hover {
  border-left: 5px solid #061ab1;
}

.accordion-button:not(.collapsed)::after {
  background: #061ab1 url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M13 8H4v2h10V8z'/%3E%3C/svg%3E") no-repeat 0 0;
  background-size: 24px 24px;
}
